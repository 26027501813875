@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.legal-content h1 {
  margin-top: 10px;
  margin-bottom: 20px;
}
.legal-content h2 {
  margin-top: 8px;
  margin-bottom: 15px;
}
.legal-content p {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer-content p {
  color: "black";
}
.footer-content p:hover {
  color: #f20c2e;
  text-decoration: underline;
}

.footerCopyright a {
  color: black;
  font-weight: 700;
  text-decoration: none;
}

.footerCopyright a:hover {
  color: #f20c2e;
  font-weight: 700;
  text-decoration: underline;
}
